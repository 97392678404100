import { Routes } from '@angular/router';

export const ROUTES: Routes = [
  {
    path: 'notifications',
    loadComponent: () =>
      import('./notifications/panel/notification-panel.component').then(
        (c) => c.NotificationPanelComponent,
      ),
  },
  {
    path: 'validations',
    loadComponent: () =>
      import('./validations/panel/validation-panel.component').then(
        (c) => c.ValidationPanelComponent,
      ),
  },
  {
    path: 'tips',
    loadComponent: () =>
      import('./tips/panel/tips-panel.component').then(
        (c) => c.TipsPanelComponent,
      ),
  },
  {
    path: 'contexthelp',
    loadComponent: () =>
      import('./context-help/panel/context-help-panel.component').then(
        (c) => c.ContextHelpPanelComponent,
      ),
  },
];
